body {
  margin: 0;
  font-family: "Montserrat", sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.text-blue {
  color: #0096c8;
}
.text-grey {
  color: #858585 !important;
}

.background-color-principal{
  background-color: #0096c8;

}
